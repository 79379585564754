import { Injectable } from '@angular/core';
import { JwtDecoded } from '@shared/types/jwt.type';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  decode(jwt: string): JwtDecoded {
    if (!jwt) return null;

    return jwtDecode(jwt);
  }
}
